module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "crm-legacy-global-containers": "static-1.21411"
    },
    "depPathPrefixes": {
      "crm-legacy-global-containers": "/crm-legacy-global-containers/static-1.21411"
    },
    "project": "crm-legacy-global-containers",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};